
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums.ts";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { pt } from 'yup-locale-pt';

export default defineComponent({
  name: "login",
  components: {
    Field,
    Form,
    ErrorMessage
  },
  setup() {
    Yup.setLocale(pt);

    const store = useStore();
    const router = useRouter();

    const submitButton = ref<HTMLElement | null>(null);

    //Create form validation object
    const login = Yup.object().shape({
      login: Yup.string()
        .email()
        .required()
        .label("Email"),
      senha: Yup.string()
        .min(4)
        .required()
        .label("Senha")
    });

    //Form submit function
    const onSubmitLogin = values => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      // Dummy delay
      setTimeout(() => {
        // Send login request
        store
          .dispatch(Actions.LOGIN, values)
          .then(() => {
            router.push({ name: "Dashboard" });
          })
          .catch(() => {
            Swal.fire({
              text: "Email e/ou senha inválidos",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Tente de novo!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger"
              }
            });
          });

        //Deactivate indicator
        submitButton.value?.removeAttribute("data-kt-indicator");
      }, 2000);
    };

    return {
      onSubmitLogin,
      login,
      submitButton
    };
  }
});
